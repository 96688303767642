import { Component, OnInit } from '@angular/core';
import { version } from '@version';
import { Observable, of } from 'rxjs';
import { AppSettingsQuery, LanguageService, ListValue } from 'chronos-shared';
import { HeaderService, PileTurnerWorkCenterService } from '@pt/core/services';
import { map, share } from 'rxjs/operators';

@Component({
  selector: 'pt-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public version = version;

  public currentLanguage$!: Observable<string>;
  public hasWorkCenterSelection$: Observable<boolean>;
  public currentPileTurnerName$!: Observable<string>;
  public pileTurnerOptions$!: Observable<ListValue[]>;
  public supportedLanguages$: Observable<ListValue[]>;

  public currentPileTurnerId?: number;
  public isProductionEnvironment$: Observable<boolean>;

  constructor(
    private languageService: LanguageService,
    private headerService: HeaderService,
    public appSettingsQuery: AppSettingsQuery,
    private pileTurnerWorkCenterService: PileTurnerWorkCenterService
  ) {}

  public ngOnInit(): void {
    this.currentPileTurnerId = this.pileTurnerWorkCenterService.getPileTurnerId();
    this.currentLanguage$ = this.languageService.language$;
    this.hasWorkCenterSelection$ = this.appSettingsQuery.isWorkCenterSelectionEnabled$;
    this.pileTurnerOptions$ = this.headerService.getPileTurners().pipe(share());
    this.currentPileTurnerName$ = this.getCurrentPileTurnerName();
    this.isProductionEnvironment$ = this.appSettingsQuery.isProductionEnvironment$;

    this.hasWorkCenterSelection$.subscribe((feature) => {
      console.info(`hasWorkCenterSelection: ${feature}`);
    });

     // added temporarily, will be replaced with proper solution.
     setTimeout(() => {
      this.supportedLanguages$ = this.languageOptions();
    }, 100);
  }

  public onLanguageSelected(lang: string): void {
    this.languageService.setDefaultLanguage(lang);
  }

  public onPileTurnerChange(newPileTurnerId: number) {
    this.pileTurnerWorkCenterService.setPileTurnerId(newPileTurnerId);
    location.reload();
  }

  private getCurrentPileTurnerName(): Observable<string> {
    return this.pileTurnerOptions$.pipe(
      map((list) => list.find((listValue) => Number(listValue.value) === this.currentPileTurnerId)),
      map((listValue) => listValue?.label || '')
    );
  }

  private languageOptions(): Observable<ListValue[]> {
    return of([
      { label: 'English', value: 'en' },
      { label: 'Deutsch', value: 'de' },
      { label: 'Română', value: 'ro' },
      { label: 'Français', value: 'fr' },
      { label: 'Polski', value: 'pl' },
      { label: 'Debug', value: 'dbg' }
    ]);
  }
}
